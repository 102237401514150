import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {checkMultipleAxios, helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import Api from "../../helper/Api";
import config from "../../config/config";
import {Box, CardContent, Grid, Link, MenuItem, Paper, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";
import PropTypes from "prop-types";

const Export = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const csvLinkRef = useRef();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [selectedAssessment, setSelectedAssessment] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [csvData, setCsvData] = useState('');
    const [csvDataName, setCsvDataName] = useState('test.csv');

    const [assessments, setAssessments] = useState([]);
    const [projects, setProjects] = useState([]);

    const [loadingData, setLoadingData] = useState(true);
    const [loadingAssessments, setLoadingAssessments] = useState(true);
    const [loadingProjects, setLoadingProjects] = useState(true);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const getAssessments = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ]);

        Api.getApi(
            'procedures' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    setAssessments(res.data['hydra:member']);
                }

                setLoadingAssessments(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getProjects = useCallback(() => {
        Api.getApi(
            'projects',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProjects(res.data['hydra:member']);
                }

                setLoadingProjects(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        if (!mounted) {
            getAssessments();
            if (props.hasProject) {
                getProjects();
            }

            setMounted(true);
        }
    }, [mounted, getAssessments, getProjects, props.hasProject]);

    useEffect(() => {
        let checkAllAxios;

        if (props.hasProject) {
            checkAllAxios = checkMultipleAxios(
                [
                    loadingAssessments,
                    loadingProjects
                ],
                [
                    {
                        name: 'assessment',
                        value: assessments
                    },
                    {
                        name: 'project',
                        value: projects
                    }
                ],
                intl
            )
        } else {
            checkAllAxios = checkMultipleAxios(
                [
                    loadingAssessments
                ],
                [
                    {
                        name: 'assessment',
                        value: assessments
                    },
                ],
                intl
            )
        }

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            setLoadingData(false);
        }
    }, [loadingAssessments, loadingProjects, assessments, projects, props.hasProject, intl]);

    const onSubmit = (event) => {
        event.preventDefault();
        resetNotifications();

        let url = props.url

        if (props.hasProject) {
            const filter = (selectedProject) ? ApiFilterBuilder([
                {
                    name: 'project',
                    value: selectedProject,
                }
            ]) : '';

            url = url.replace('{filter}', filter);
        }

        url = url.replace('{selectedLanguage}', selectedLanguage);
        url = url.replace('{selectedAssessment}', selectedAssessment);

        axios.get(config.apiUrl + url, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setCsvDataName(res.data.name);
                    setCsvData(res.data.content);
                    csvLinkRef.current.click();
                    showSuccess();
                } else {
                    setNotificationError(true);
                }
            })
            .catch(catchErrors);
    }

    const getContent = () => {
        if (loadingData) {
            return null;
        }

        return (
            <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant={'outlined'} className={'export-pin--form-control'}
                                     id={'export-pin-language'}>
                            <InputLabel>
                                {GlobalTransIntl('language', intl)}
                            </InputLabel>
                            <Select
                                labelId="form-languages--select-label"
                                value={selectedLanguage}
                                label={GlobalTransIntl('language', intl)}
                                onChange={(event) => setSelectedLanguage(event.target.value)}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem value="en">
                                    {GlobalTransIntl('translation_default', intl)}
                                </MenuItem>
                                {
                                    state.languages.map((language) => (
                                        <MenuItem key={language.code} value={language['code']}>
                                            {language.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant={'outlined'} className={'export-pin--form-control'}
                                     id={'export-pin-assessment'} required>
                            <InputLabel>
                                {GlobalTransIntl('assessment', intl)}
                            </InputLabel>
                            <Select
                                labelId="export-pin--assessment-select"
                                value={selectedAssessment}
                                onChange={(event) => {
                                    setSelectedAssessment(event.target.value)
                                }}
                                label={GlobalTransIntl('assessment', intl)}
                                IconComponent={selectIconComponent}
                            >
                                {
                                    assessments.map((item) =>
                                        <MenuItem value={item['id']} key={item.id} data-name={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        props.hasProject &&
                        <Grid item xs={12} md={6}>
                            <FormControl variant={'outlined'} className={'export-pin--form-control'}
                                         id={'export-pin-project'}>
                                <InputLabel>
                                    {GlobalTransIntl('project', intl)}
                                </InputLabel>
                                <Select
                                    labelId="export-pin--project-select"
                                    value={selectedProject}
                                    onChange={(event) => {
                                        setSelectedProject(event.target.value)
                                    }}
                                    label={GlobalTransIntl('project', intl)}
                                    IconComponent={selectIconComponent}
                                >
                                    <MenuItem value="">-</MenuItem>
                                    {
                                        projects.map((item) =>
                                            <MenuItem value={item['id']} key={item.id} data-name={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button variant="primary" type="submit" className={`forced_choice_weighting--create-submit`}>
                            {GlobalTransIntl('export', intl)}
                        </Button>
                    </Grid>
                    <Box display={'none'}>
                        <Link href={`data:text/csv;charset=utf-8,%EF%BB%BF${csvData}`} download={csvDataName}
                              ref={csvLinkRef}>download</Link>
                    </Box>
                </Grid>
            </form>
        );
    }

    return (
        <Paper>
            <CardContent>
                <h1>Pin Export</h1>
                <Spinner show={loadingData} rowClass={'p-5'}/>
                <Box pt={2} pr={2} pl={2} display={(notificationError || notificationSuccess) ? 'block' : 'none'}>
                    <Notifications
                        success={notificationSuccess}
                        error={notificationError}
                        errorMessage={notificationErrorMessage}
                    />
                </Box>
                {getContent()}
            </CardContent>
        </Paper>
    );
};

Export.propTypes = {
    url: PropTypes.string.isRequired,
    hasProject: PropTypes.bool,
}

Export.defaultProps = {
    hasProject: false,
}

export default Export;
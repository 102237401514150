import React, {createRef, useCallback, useEffect, useState} from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip
} from "@material-ui/core";

import ModulesContent from "./ModulesContent";
import ModulesDragables from "./ModulesDragables";
import {v4 as uuidv4} from "uuid";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import {helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import Api from "../../helper/Api";
import {useStore} from "../../store/useStore";
import axios from "axios";
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";
import config from "../../config/config";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import ModuleTimePicker from "./ModuleTimePicker";
import Ocs from "./Ocs";
import CustomDialog from "../../helper/CustomDialog";

const Modules = () => {
    const intl = useIntl();
    const { state, dispatch } = useStore();

    const formSubmitButtonRef = createRef();

    const [cancelToken] = useState(axios.CancelToken.source());
    const getDefaultTabValue = useCallback(() => (
        {
            id: uuidv4(),
            responseTime: 0,
            elements: []
        }
    ), []);
    const [mounted, setMounted] = useState(false);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [content, setContent] = useState((state.editForm.modulJson && state.editForm.modulJson.pages) ? state.editForm.modulJson.pages : [getDefaultTabValue()]);
    const [references, setReferences] = useState((state.editForm.modulJson && state.editForm.modulJson.references) ? state.editForm.modulJson.references : []);
    const [pageIndex, setPageIndex] = useState(0);

    const [dimensions, setDimensions] = useState(false);
    const [loadingDimensions, setLoadingDimensions] = useState(true);

    const [answers, setAnswers] = useState(state.editForm.answers || {});

    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm.id : null);
    const [name, setName] = useState(state.editForm.name || '');
    const [maxPages, setMaxPages] = useState(state.editForm.maxPages || '');
    const [dimension, setDimension] = useState((state.editForm.modulJson && state.editForm.modulJson.dimension) ? state.editForm.modulJson.dimension : '');
    const [time, setTime] = useState((state.editForm.modulJson && state.editForm.modulJson.responseTime) ? state.editForm.modulJson.responseTime : 0);
    const [oldVersion, setOldVersion] = useState(state.editForm.id || '');
    const [dialogStatus, setDialogStatus] = React.useState(false);

    // Checkboxes
    const [checkboxTimer, setCheckboxTimer] = useState(!!(state.editForm.modulJson && state.editForm.modulJson.responseTime));
    const [checkboxDimension, setCheckboxDimension] = useState(!!(state.editForm.modulJson && state.editForm.modulJson.dimension));
    const [checkboxExample, setCheckboxExample] = useState(state.editForm.example || false);
    const [checkboxRandomPages, setCheckboxRandomPages] = useState(state.editForm.randomPages || false);
    const [checkboxOcs, setCheckboxOcs] = useState(!!(state.editForm.modulJson && state.editForm.modulJson.ocs));
    const [noNewVersion, setNoNewVersion] = useState(false);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const showSuccess = useCallback(() => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);

        setTimeoutWatcher(setTimeout(() => {
            resetNotifications();
        }, 3000));
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const addNewTab = useCallback(() => {
        setContent(prev => ([
                ...prev,
                getDefaultTabValue()
            ])
        );
    }, [getDefaultTabValue]);

    const updateTabResponseTime = useCallback((newResponseTime) => {
        setContent(prev => {
            const newContent = [...prev];

            newContent[pageIndex].responseTime = newResponseTime;

            return newContent;
        });
    }, [pageIndex]);

    const removeTab = useCallback(() => {
        setContent(prev => {
            const newContent = [...prev];

            newContent.splice(pageIndex, 1);

            return newContent;
        });

        setPageIndex(0);
    }, [pageIndex]);

    const addContent = useCallback((item, index = null) => {
        const newId = item.id || uuidv4();
        const newElement = {...item, id: newId};
        const newContent = [...content];
        const elements = newContent[pageIndex]['elements'];

        if (index !== null) {
            elements.splice(index, 0, newElement);
        } else {
            elements.push(newElement);
        }

        setContent(newContent);

        return newId;
    }, [pageIndex, content]);

    const removeContent = useCallback((itemId) => {
        let newContent = [...content];

        newContent[pageIndex]['elements'] = newContent[pageIndex]['elements'].filter((element) => itemId !== element.id);

        setContent(newContent);
    }, [content, pageIndex]);

    const findContent = useCallback((contentId, grid = false, gridCol = null) => {
        const elements = (grid && gridCol !== null)
            ? content[pageIndex]['elements'].find((element) => element.id === grid)['colElements'][gridCol]
            : content[pageIndex]['elements'];

        const element = elements.filter((element) => element.id === contentId)[0];
        const elementIndex = elements.indexOf(element);

        return {
            element,
            index: elementIndex
        }
    }, [content, pageIndex]);

    const moveContent = useCallback((contentId, atIndex) => {
        const {element, index} = findContent(contentId);
        const newContent = [...content];

        let elements = newContent[pageIndex]['elements'];

        elements.splice(index, 1);
        elements.splice(atIndex, 0, element);

        setContent(newContent);
    }, [content, findContent, pageIndex]);

    const removeContentFromGrid = useCallback((contentId, gridId, gridColIndex) => {
        const newContent = [...content];
        const grid = newContent[pageIndex]['elements'].find((element) => gridId === element.id);

        grid.colElements[gridColIndex] = grid.colElements[gridColIndex].filter((element) => element.id !== contentId);

        setContent(newContent);
    }, [content, pageIndex]);

    const updateContent = useCallback((updateTargetElement, newElementContent) => {
        const grid = updateTargetElement.grid;
        const gridCol = updateTargetElement.gridCol;
        const {element, index} = findContent(updateTargetElement.id, grid, gridCol);

        const newContent = [...content];
        const newElement = {...element, ...newElementContent};

        const elements = (grid && gridCol !== null)
            ? newContent[pageIndex]['elements'].find((element) => element.id === grid)['colElements'][gridCol]
            : newContent[pageIndex]['elements'];

        elements.splice(index, 1);
        elements.splice(index, 0, newElement);

        setContent(newContent);
    }, [content, findContent, pageIndex]);

    const updateAnswer = (elementId, answer) => {
        setAnswers(prev => ({...prev, [elementId]: answer}));
    };

    const removeAnswer = (elementId) => {
        setAnswers(prev => {
            const newAnswers = {...prev};

            delete newAnswers[elementId];

            return newAnswers;
        });
    };

    const getAnswer = useCallback((elementId) => {
        return answers[elementId];
    }, [answers]);

    const getApi = useCallback(() => {
        Api.getApi(
            'dimensions',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setDimensions(res.data['hydra:member']);
                } else {
                    showError(GlobalTransIntl('error_no_dimension', intl));
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token, showError, intl]);

    const resetPage = useCallback(() => {
        setPageIndex(0);
        setContent([getDefaultTabValue()]);
        setName('');
        setCheckboxTimer(false)
        setCheckboxDimension(false);
        setCheckboxRandomPages(false);
        setDimension('');
        setTime('');
        setNoNewVersion(false);
        setOldVersion('');
        setCheckboxOcs(false);
        setReferences([]);
    }, [getDefaultTabValue]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getApi();

            if (editForm) {
                dispatch({type: "resetEditForm"});
            }
        }
    }, [dispatch, editForm, getApi, mounted]);

    const checkAxiosResponse = useCallback((res) => {
        if (res.data) {
            showSuccess();
            resetPage();
        } else {
            showError();
        }
    }, [resetPage, showError, showSuccess]);

    const onSubmit = (event) => {
        event.preventDefault();

        if (editForm && !noNewVersion) {
            setDialogStatus(true);
        } else {
            saveModule();
        }
    }

    const saveModule = useCallback(() => {
        let modulJson = {
            pages: content
        }

        if (checkboxTimer) {
            modulJson.responseTime = time;
        }

        if (checkboxDimension) {
            modulJson.dimension = dimension;
        }

        modulJson.ocs = checkboxOcs;

        if (checkboxOcs && references) {
            modulJson.references = references;
        } else {
            delete modulJson.references;
        }

        const data = {
            name,
            modulJson,
            answers,
            example: checkboxExample,
            randomPages: checkboxRandomPages,
            maxPages: (maxPages) ? parseInt(maxPages) : null,
        };

        resetNotifications();
        clearTimeout(timeoutWatcher);

        if (editForm && noNewVersion) {
            axios.put(config.apiUrl + `/modules/${editForm}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(checkAxiosResponse)
                .catch(catchErrors);
        } else {
            if (oldVersion) {
                data.oldVersion = oldVersion;
            }

            axios.post(config.apiUrl + `/modules`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(checkAxiosResponse)
                .catch(catchErrors);
        }

    }, [name, content, answers, checkboxTimer, time, checkboxDimension, dimension, checkboxExample, checkboxRandomPages, timeoutWatcher, state.token, cancelToken.token, editForm, noNewVersion, oldVersion, checkAxiosResponse, catchErrors, maxPages, checkboxOcs, references]);

    const toggleOcs = () => {
        if (checkboxOcs) {
            const newContent = [...content];

            newContent.forEach(page => {
                if (page?.elements.length) {
                    page.elements.forEach((element, index) => {
                        if (element.elementType === 'mailLayout' || element.elementType === 'mailTasks') {
                            delete page.elements[index];
                        }
                    })
                }

            })

            setContent(newContent);
        }

        setCheckboxOcs(prev => !prev)
    }

    return (
        <Paper>
            <Spinner show={loadingDimensions} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            {
                !loadingDimensions && (dimensions.length > 0) &&
                <React.Fragment>
                        <form onSubmit={onSubmit}>
                            <Box mb={2} p={2}>
                                <Box mb={2}>
                                    <Grid container>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField label={GlobalTransIntl('name', intl)}
                                                       id={'module-create--name'}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {
                                                           setName(e.target.value)
                                                       }}
                                                       value={name}
                                                       required
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {
                                    editForm &&
                                    <Box mb={2}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={noNewVersion}
                                                    onChange={
                                                        (e) => {
                                                            setNoNewVersion(e.target.checked)
                                                        }
                                                    }
                                                    color='primary'
                                                />
                                            }
                                            label={
                                                <React.Fragment>
                                                    {GlobalTransIntl('no_new_version', intl)}
                                                    <Tooltip
                                                        title={GlobalTransIntl('tooltip_no_new_version', intl)}>
                                                        <IconButton>
                                                            <FontAwesomeIcon icon={faQuestionCircle} size={"xs"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        />
                                    </Box>
                                }
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkboxExample}
                                                               onChange={() => setCheckboxExample(prev => !prev)}
                                                               name={GlobalTransIntl('example', intl)}/>}
                                            label={GlobalTransIntl('example', intl)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkboxRandomPages}
                                                               onChange={() => setCheckboxRandomPages(prev => !prev)}
                                                               name={GlobalTransIntl('random_pages', intl)}/>}
                                            label={GlobalTransIntl('random_pages', intl)}
                                        />
                                        {
                                            checkboxRandomPages &&
                                            <Grid container>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField label={GlobalTransIntl('max_pages', intl)}
                                                               id={'module-create--max-pages'}
                                                               autoComplete={'off'}
                                                               variant="outlined"
                                                               onChange={(e) => {
                                                                   setMaxPages(e.target.value)
                                                               }}
                                                               value={maxPages}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkboxOcs}
                                                               onChange={toggleOcs}
                                                               name={GlobalTransIntl('ocs', intl)}/>}
                                            label={GlobalTransIntl('ocs', intl)}
                                        />
                                        {
                                            checkboxOcs &&
                                            <Ocs references={references} setReferences={setReferences}/>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkboxTimer}
                                                               onChange={() => setCheckboxTimer(prev => !prev)}
                                                               name={GlobalTransIntl('module_module_timer', intl)}/>}
                                            label={GlobalTransIntl('module_module_timer', intl)}
                                        />
                                        {
                                            checkboxTimer &&
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <ModuleTimePicker setValue={setTime} value={time}/>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={checkboxDimension}
                                                               onChange={() => setCheckboxDimension(prev => !prev)}
                                                               name={GlobalTransIntl('module_module_dimension', intl)}/>}
                                            label={GlobalTransIntl('module_module_dimension', intl)}
                                        />
                                        {
                                            checkboxDimension &&
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl variant={'outlined'} required>
                                                        <InputLabel
                                                            id="module-dimensions-select-label">{GlobalTransIntl('dimensions', intl)}</InputLabel>
                                                        <Select
                                                            labelId="module-dimensions-select-label"
                                                            id="module-dimensions-select"
                                                            value={dimension}
                                                            label={GlobalTransIntl('dimensions', intl)}
                                                            onChange={(event) => setDimension(event.target.value)}
                                                            IconComponent={selectIconComponent}
                                                        >
                                                            {
                                                                dimensions.map((dimension, index) => (
                                                                    <MenuItem key={index}
                                                                              value={dimension.id}>{dimension.name}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box display={'none'}>
                                <button type={"submit"} ref={formSubmitButtonRef} />
                            </Box>
                        </form>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <ModulesContent
                                    content={content}
                                    addContent={addContent}
                                    addNewTab={addNewTab}
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    moveContent={moveContent}
                                    findContent={findContent}
                                    updateContent={updateContent}
                                    removeContent={removeContent}
                                    checkboxTimer={(checkboxOcs) ? false : checkboxTimer}
                                    checkboxDimension={checkboxDimension}
                                    dimensions={dimensions}
                                    updateTabResponseTime={updateTabResponseTime}
                                    removeContentFromGrid={removeContentFromGrid}
                                    updateAnswer={updateAnswer}
                                    removeAnswer={removeAnswer}
                                    getAnswer={getAnswer}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ModulesDragables
                                    removeContent={removeContent}
                                    removeContentFromGrid={removeContentFromGrid}
                                    removeTab={removeTab}
                                    pageIndex={pageIndex}
                                    formButtonRef={formSubmitButtonRef}
                                    ocs={checkboxOcs}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
            }
            <CustomDialog
                text={GlobalTrans('dialog_new_version')}
                agreeFunction={saveModule}
                closeFunction={() => setDialogStatus(false)}
                open={dialogStatus}/>
        </Paper>
    );
};

export default Modules;
import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {getTranslationFromTranslations, helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import Api from "../../helper/Api";
import {FormattedMessage} from "react-intl.macro";
import Spinner from "../spinner/Spinner";
import {Box, CardContent, Grid, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import FormControl from "@material-ui/core/FormControl";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import TranslationsInput from "../translations/TranslationsInput";
import config from "../../config/config";
import pages from "../../config/pages";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

const DataProtectionsCreate = () => {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [text, setText] = useState(state.editForm.text || '');
    const [selectedProject, setSelectedProject] = useState(editForm?.project['@id'] || '');
    const [selectedProcedureObject, setSelectedProcedureObject] = useState(editForm?.procedureObject['@id'] || '');

    const [loadingProjects, setLoadingProjects] = useState(true);
    const [projects, setProjects] = useState([]);
    const [loadingProcedures, setLoadingProcedures] = useState(true);
    const [procedures, setProcedures] = useState([]);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    };

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getProjects = useCallback(() => {
        Api.getApi(
            'projects',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProjects(res.data['hydra:member']);
                }

                setLoadingProjects(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getProcedures = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ]);

        Api.getApi(
            'procedures' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProcedures(res.data['hydra:member']);
                }

                setLoadingProcedures(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }

            cancelToken.cancel();
        };
    }, [timeoutWatcher, cancelToken]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getProjects();
            getProcedures();
        }
    }, [state.editForm, dispatch, editForm, mounted, getProjects, getProcedures]);

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        const data = {
            text,
            project: selectedProject,
            procedureObject: selectedProcedureObject
        }

        if (editForm) {
            axios.put(config.apiUrl + `/data_protections/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'Content-Type': 'application/ld+json'}}))
                .then(res => {
                    if (res.data) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            dispatch({type: "setPage", payload: pages.DATA_PROTECTION_OVERVIEW});
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/data_protections`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();

                        setText('');
                        setSelectedProject('');
                        setSelectedProcedureObject('');
                        setTranslations([]);
                    } else {
                        showError();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data['violations']) {
                        showError(error.response.data['violations'][0].message);
                    } else if (error.response) {
                        showError();
                    }
                });
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='data_protection.edit.headline' defaultMessage='Edit data protection' />;
        }

        return <FormattedMessage id='data_protection.create.headline' defaultMessage='Create data protection' />;
    };

    return (
        <Paper>
            <Spinner show={loadingProjects || loadingProcedures} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingProjects && !loadingProcedures &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                            <Grid item>
                                <TranslationLanguages
                                    selectedLanguage={translationLanguage}
                                    setLanguage={setTranslationLanguage}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <form className={`data-protection--create-form`} onSubmit={sendSubmit}>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl variant={'outlined'} required>
                                        <InputLabel id="data-protection-create--select-project">
                                            {GlobalTransIntl('project', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="data-protection-create--select-project"
                                            id="data-protection-create-select-project"
                                            value={selectedProject}
                                            label={GlobalTransIntl('project', intl)}
                                            onChange={(event) => setSelectedProject(event.target.value)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                projects.map((project, index) => (
                                                    <MenuItem key={index} value={project['@id']}>
                                                        {getTranslationFromTranslations(project.translations, state.localeIRI, 'name', project.name)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl variant={'outlined'} required>
                                        <InputLabel id="data-protection-create--select-procedure">
                                            {GlobalTransIntl('assessment', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="data-protection-create--select-procedure"
                                            id="data-protection-create-select-procedure"
                                            value={selectedProcedureObject}
                                            label={GlobalTransIntl('assessment', intl)}
                                            onChange={(event) => setSelectedProcedureObject(event.target.value)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                procedures.map((procedure, index) => (
                                                    <MenuItem key={index} value={procedure['@id']}>
                                                        {getTranslationFromTranslations(procedure.translations, state.localeIRI, 'name', procedure.name)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TranslationsInput
                                        field={'text'}
                                        defaultValue={text}
                                        setDefaultValue={setText}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('text', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            multiline: true,
                                            minRows: 15,
                                            required: true,
                                            id: `data-protection-create--text`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Button variant="primary" type="submit" className={`data-protection--create-submit`}>
                            {
                                (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                            }
                        </Button>
                    </form>
                </CardContent>
            }
        </Paper>
    );
};

export default DataProtectionsCreate;
import React, {useCallback, useEffect, useState} from 'react';
import NormgroupValuesForm from "../normgroupValuesForm/NormgroupValuesForm";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import config from "../../config/config";
import pages from "../../config/pages";
import {Box} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import {deepCopy, helperCatchErrors, scrollToTop} from "../../helper/Helper";
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import Button from "react-bootstrap/cjs/Button";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Spinner from "../spinner/Spinner";

const AssessmentNormgroupsForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [mounted, setMounted] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [updatingProcedure, setUpdatingProcedure] = useState(true);
    const [cancelToken] = useState(axios.CancelToken.source());
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [procedureObject, setProcedureObject] = React.useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [normgroupValues, setNormgroupValues] = React.useState((state.editForm.normgroupValues) ? state.editForm.normgroupValues : []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const showSuccess = useCallback(() => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);

        setTimeoutWatcher(setTimeout(() => {
            dispatch({
                type: "setPage",
                payload: pages.ASSESSMENT_CREATE
            });
        }, 3000));
    }, [dispatch]);

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const updateProcedure = useCallback(() => {
        const data = {name: procedureObject.name};

        axios.patch(config.apiUrl + `/procedures/${procedureObject.id}`, data, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'application/merge-patch+json'}
        }))
            .then(res => {
                if (res.data && res.data['@id']) {
                    setProcedureObject(res.data);
                    setUpdatingProcedure(false);
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, procedureObject.id, procedureObject.name, showError, state.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (!procedureObject) {
                setUpdatingProcedure(false);
                showError(GlobalTransIntl('error_no_assessment_id', intl));
            } else {
                dispatch({type: "resetEditForm"});
                updateProcedure();
            }
        }
    }, [mounted, dispatch, updateProcedure, intl, procedureObject, showError]);

    const getParseFloatedNormgroupValues = useCallback(() => {
        const result = deepCopy(normgroupValues);

        result.forEach((element) => {
            element['average'] = parseFloat(element['average']);
            element['standardDeviation'] = parseFloat(element['standardDeviation']);
        });

        return result;
    }, [normgroupValues]);

    const submitNormgroupValues = useCallback(() => {
        resetNotifications();

        procedureObject.normgroupValues = getParseFloatedNormgroupValues();

        if (procedureObject.reportScala) {
            procedureObject.reportScala = procedureObject.reportScala['@id'];
        }

        axios.put(config.apiUrl + `/procedures/${procedureObject.id}`, procedureObject, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'content-type': 'application/ld+json'}
        }))
            .then(res => {
                if (res.data && res.data['@id']) {
                    showSuccess();

                    dispatch({type: "setEditForm", payload: procedureObject});
                    dispatch({type: "setPage", payload: pages.ASSESSMENT_DIMENSION_TEMPLATES});
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }, [procedureObject, cancelToken.token, catchErrors, showError, showSuccess, state.token, getParseFloatedNormgroupValues, dispatch]);

    return (
        <Paper>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            <Spinner show={updatingProcedure} rowClass={'p-5'}/>
            {
                (!updatingProcedure && procedureObject) &&
                <NormgroupValuesForm
                    catchErrors={catchErrors}
                    showError={showError}
                    normgroupValues={normgroupValues}
                    setNormgroupValues={setNormgroupValues}
                    procedureObject={procedureObject}
                    setProcedureObject={setProcedureObject}
                    setReady={setIsReady}
                    isDimensions={true}
                />
            }
            {
                (isReady) &&
                <Box p={2}>
                    <Button variant="primary" type="submit" onClick={submitNormgroupValues}>
                        {GlobalTransIntl('button_save', intl)}
                    </Button>
                </Box>
            }
        </Paper>
    );
};

export default AssessmentNormgroupsForm;
import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import Api from "../../helper/Api";
import axios from "axios";
import {deepCopy, helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import Spinner from "../spinner/Spinner";
import {Box, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import NormgroupValuesForm from "../normgroupValuesForm/NormgroupValuesForm";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import MenuItem from "@material-ui/core/MenuItem";
import pages from "../../config/pages";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";
import Interpretations from "../interpretations/Interpretations";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";

const OverallTemplateForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [loadingProcedures, setLoadingProcedures] = React.useState(true);
    const [loadingOverallTemplates, setLoadingOverallTemplates] = React.useState(true);
    const [overallTemplate, setOverallTemplate] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [selectedProcedure, setSelectedProcedure] = React.useState(overallTemplate?.procedureObject['@id'] || '');

    const [procedures, setProcedures] = useState([]);
    const [overallTemplates, setOverallTemplates] = useState([]);

    const [procedureObject] = React.useState((overallTemplate?.procedureObject) ?? null);
    const [normgroupValues, setNormgroupValues] = React.useState((overallTemplate?.normgroupValues) ?? []);
    const [interpretations, setInterpretations] = useState(state.editForm.overallTemplateScales || []);

    const [translationLanguage, setTranslationLanguage] = useState('');

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const showSuccess = useCallback(() => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);

        setTimeoutWatcher(setTimeout(() => {
            dispatch({
                type: "setPage",
                payload: pages.OVERALL_TEMPLATE_OVERVIEW
            });
        }, 3000));
    }, [dispatch]);

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getProcedures = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ]);

        Api.getApi(
            'procedures' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProcedures(res.data['hydra:member']);
                }

                setLoadingProcedures(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getOverallTemplates = useCallback(() => {
        Api.getApi(
            'overall_templates',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setOverallTemplates(res.data['hydra:member']);
                }

                setLoadingOverallTemplates(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            dispatch({type: "resetEditForm"});

            if (overallTemplate) {
                setLoadingProcedures(false);
                setLoadingOverallTemplates(false);
            } else {
                getProcedures();
                getOverallTemplates();
            }

        }
    }, [getProcedures, getOverallTemplates, mounted, overallTemplate, dispatch]);

    useEffect(() => {
        if (selectedProcedure && !overallTemplate) {
            setOverallTemplate({
                procedureObject: procedures.find(procedure => procedure['@id'] === selectedProcedure)
            });
        }
    }, [selectedProcedure, overallTemplate, procedures]);

    const getFilteredProcedures = () => {
        return procedures.filter(procedure => !overallTemplates.find(overallTemplate => overallTemplate.procedureObject['@id'] === procedure['@id']));
    }

    const AssessmentSelect = () => {
        if (procedureObject) {
            return <Box p={2}>
                <h2>{GlobalTransIntl('assessment', intl)}: {procedureObject.name}</h2>
            </Box>;
        }

        if (!loadingProcedures && !loadingOverallTemplates && procedures.length > 0) {
            return (
                <Box p={2}>
                    <FormControl variant={'outlined'} id={'overall-template-assessment'}>
                        <InputLabel>
                            {GlobalTransIntl('assessment', intl)}
                        </InputLabel>
                        <Select
                            labelId="overall-template-assessment-select"
                            value={selectedProcedure}
                            onChange={(event) => {
                                setSelectedProcedure(event.target.value);
                            }}
                            label={GlobalTransIntl('assessment', intl)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                getFilteredProcedures().map((procedure) => (
                                    <MenuItem
                                        value={procedure['@id']}
                                        key={procedure['@id']}
                                    >
                                        {procedure.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            );
        }

        return null;
    }

    const getParseFloatedNormgroupValues = useCallback(() => {
        const result = deepCopy(normgroupValues);

        result.forEach((element, elementKey) => {
            if (element['average'] && element['standardDeviation']) {
                element['average'] = parseFloat(element['average']);
                element['standardDeviation'] = parseFloat(element['standardDeviation']);
            } else {
                delete result[elementKey];
            }
        });

        return result.filter(resultItem => resultItem);
    }, [normgroupValues]);

    const submit = useCallback(() => {
        resetNotifications();

        const data = deepCopy(overallTemplate);

        data.normgroupValues = getParseFloatedNormgroupValues();
        data.overallTemplateScales = interpretations;
        data.procedureObject = overallTemplate.procedureObject['@id'];

        if (!data?.id) {
            axios.post(config.apiUrl + '/overall_templates', data, config.axiosConfig(state.token, {cancelToken: cancelToken.token,}))
                .then(res => {
                    if (res.data && res.data['@id']) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.put(config.apiUrl + `/overall_templates/${data.id}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'content-type': 'application/ld+json'}
            }))
                .then(res => {
                    if (res.data && res.data['@id']) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }, [overallTemplate, getParseFloatedNormgroupValues, cancelToken.token, catchErrors, showError, showSuccess, state.token, interpretations]);

    return (
        <Paper>
            <Spinner show={loadingProcedures || loadingOverallTemplates} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            <AssessmentSelect/>
            {
                (!!overallTemplate) &&
                <>
                    <NormgroupValuesForm
                        catchErrors={catchErrors}
                        showError={showError}
                        normgroupValues={normgroupValues}
                        setNormgroupValues={setNormgroupValues}
                        setReady={setIsReady}
                    />
                    <Box p={2}>
                        <Box maxWidth={500} margin={'0 auto'}>
                            <TranslationLanguages
                                selectedLanguage={translationLanguage}
                                setLanguage={setTranslationLanguage}
                            />
                        </Box>
                        <Interpretations
                            interpretations={interpretations}
                            setInterpretations={setInterpretations}
                            translationLanguage={translationLanguage}
                        />
                    </Box>
                </>
            }
            {
                (isReady) &&
                <Box p={2}>
                    <Button variant="primary" type="submit" onClick={submit}>
                        {GlobalTransIntl('button_save', intl)}
                    </Button>
                </Box>
            }
        </Paper>
    );
};

export default OverallTemplateForm;
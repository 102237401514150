import React, {createRef, useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import ModuleElementDimension from "../../../helper/ModuleElementDimension";
import {v4 as uuidv4} from "uuid";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import TranslationsInput from "../../translations/TranslationsInput";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
    selectIconComponent,
    inputOnlyFloatAndNegative,
    helperCatchErrors,
    deepCopy, scrollToTop
} from "../../../helper/Helper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ImageUpload from "../../imageUpload/ImageUpload";
import Notifications from "../../notifications/Notifications";
import config from "../../../config/config";
import {useStore} from "../../../store/useStore";
import ModuleTimePicker from "../ModuleTimePicker";
import Divider from "@material-ui/core/Divider";

const AdaptiveElement = (props) => {
    const { state } = useStore();
    const elementName = GlobalTrans(props.title);
    const intl = useIntl();
    const defaultItem = {
        name: '',
        question: '',
        options: [],
        imageRef: createRef(),
        imageUpload: false,
        image: '',
        imageOriginal: '',
        time: 0,
    };

    const defaultItemAnswer = {
        answer: '',
        right: {
            finished: false,
            value: ''
        },
        wrong: {
            finished: false,
            value: ''
        }
    }

    const prepareItems = () => {
        if (props.items.length) {
            return props.items.map(item => {
                if (item.image) {
                    item.imageOriginal = item.image;
                }

                item.imageRef = createRef();
                item.imageUpload = false;

                return item;
            });
        }

        return [];
    }

    // optional - when having answers
    const [mounted, setMounted] = useState(false);
    const [dimension, setDimension] = useState(props.dimension || '');
    const [answers, setAnswers] = useState((props.answer !== undefined) ? props.answer : []);
    const [items, setItems] = useState(prepareItems());
    const [imageUploading, setImageUploading] = useState(false);
    const [itemImages, setItemImages] = useState({});
    const [checkboxGlobalTime, setCheckboxGlobalTime] = useState(!!props.globalTime);
    const [globalTime, setGlobalTime] = useState(props.globalTime || 0);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const setItemTranslations = (itemIndex, itemTranslation) => {
        setTranslations(prev => {
            const newPrev = deepCopy(prev);

            newPrev[itemIndex] = itemTranslation;

            return newPrev;
        })
    }

    const findTranslation = useCallback((language, translations) => {
        return translations.find((translation => translation.language === language));
    }, []);

    const setItemImagesToTranslations = useCallback(() => {
        const newTranslations = deepCopy(translations);

        Object.keys(itemImages).forEach(key => {
            itemImages[key].forEach((itemImage, itemImageIndex) => {
                if (itemImage.image) {
                    const translation = findTranslation(key, newTranslations[itemImageIndex]);

                    if (translation) {
                        translation.image = itemImage.image;
                    }
                }
            });
        });

        setTranslations(newTranslations);

        return newTranslations;
    }, [itemImages, findTranslation, translations]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            const newImages = {};

            state.languages.forEach((language) => {
                const itemImages = [];

                items.forEach((item, itemIndex) => {
                    itemImages.push({
                        ref: createRef(),
                        upload: false,
                        original: findTranslation(language['@id'], translations[itemIndex])?.image || '',
                        image: '',
                    });
                })

                newImages[language['@id']] = itemImages;
            });

            setItemImages(newImages);
        }
    }, [mounted, props, state.languages, findTranslation, items, translations]);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const saveModal = useCallback(() => {
        resetNotifications();

        items.forEach((item) => {
            item.imageRef.current.sendFile();
        });

        Object.keys(itemImages).forEach(key => {
            itemImages[key].forEach(itemImageItem => {
                itemImageItem.ref.current.sendFile();
            })
        });

        setImageUploading(true);
    }, [items, itemImages]);

    const isEveryItemImageUploaded = useCallback(() => {
        return Object.keys(itemImages).findIndex(
                key => itemImages[key].findIndex(
                    (itemImage) => itemImage.upload === false
                ) !== -1
            ) === -1;
    }, [itemImages]);

    useEffect(() => {
        if (imageUploading && !items.find(item => item.imageUpload === false) && isEveryItemImageUploaded()) {
            setImageUploading(false);
            const newTranslations = setItemImagesToTranslations();

            const data = {
                items,
                translations: newTranslations,
                dimension,
                globalTime,
            };

            props.updateAnswer(props.id, answers);
            props.updateContent(props, data);
        }
    }, [items, answers, dimension, translations, props, imageUploading, isEveryItemImageUploaded, setItemImagesToTranslations, globalTime]);

    const addDefaultItemImages = () => {
        setItemImages(prev => {
            const newItemImages = deepCopy(prev);

            state.languages.forEach((language) => {
                const itemImages = newItemImages[language['@id']];

                itemImages.push({
                    ref: createRef(),
                    upload: false,
                    original: '',
                    image: '',
                });
            });

            return newItemImages;
        });
    }

    const addItem = () => {
        const newItemId = uuidv4();

        setItems((prev) => {
            const newItems = deepCopy(prev);
            const newItem = deepCopy(defaultItem);

            newItem.id = newItemId;
            newItems.push(newItem);

            return newItems;
        });

        setAnswers((prev) => {
            const newItemAnswer = deepCopy(defaultItemAnswer);

            newItemAnswer.id = newItemId;
            prev.push(newItemAnswer);

            return prev;
        });

        setTranslations(prev => {
            prev.push([]);

            return prev;
        });

        addDefaultItemImages();
    };

    const updateItem = (itemIndex, updateIndex, value) => {
        setItems((prev) => {
            const newItems = deepCopy(prev);

            newItems[itemIndex][updateIndex] = value;

            return newItems;
        });
    };

    const updateAnswer = (answerItemIndex, updateIndex, value) => {
        setAnswers((prev) => {
            const newAnswers = deepCopy(prev);

            newAnswers[answerItemIndex][updateIndex] = value;

            return newAnswers;
        });
    };

    const updateAnswerType = (answerItemIndex, updateType, updateIndex, value) => {
        setAnswers((prev) => {
            const newAnswers = deepCopy(prev);

            newAnswers[answerItemIndex][updateType][updateIndex] = value;

            return newAnswers;
        });
    }

    const itemFinish = (index, updateType, checkboxChecked = false) => (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkboxChecked}
                        onChange={(e) => {
                            updateAnswerType(index, updateType, 'value', '');
                            updateAnswerType(index, updateType, 'finished', e.target.checked);
                        }}
                        inputProps={{'aria-label': GlobalTransIntl('finished', intl)}}
                        title={GlobalTransIntl('finished', intl)}
                    />
                }
                label={GlobalTransIntl('finished', intl)}
            />
            <Box mb={2}>
                {
                    (
                        (checkboxChecked === true) &&
                        <TextField label={GlobalTransIntl('finish_value', intl)}
                                   autoComplete={'off'}
                                   variant="outlined"
                                   onChange={(e) => inputOnlyFloatAndNegative(e, () => updateAnswerType(index, updateType, 'value', e.target.value))}
                                   value={answers[index][updateType].value}
                                   required
                        />
                    )
                    ||
                    <FormControl variant={'outlined'}>
                        <InputLabel>
                            {GlobalTransIntl('adaptive_element', intl)}
                        </InputLabel>
                        <Select
                            value={answers[index][updateType].value}
                            onChange={(e) => updateAnswerType(index, updateType, 'value', e.target.value)}
                            label={GlobalTransIntl('adaptive_element', intl)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                items.filter(item => item.id !== items[index].id).map(item => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
            </Box>
        </>
    );

    const updateItemImages = (language, itemIndex, key, value) => {
        setItemImages(prev => {
            const newPrev = deepCopy(prev);

            newPrev[language][itemIndex][key] = value;

            return newPrev;
        })
    }

    const getImageUploadOriginalLink = (original) => {
        if (original && original?.contentUrl) {
            return (
                <Box sx={{overflowWrap: 'break-word'}} mt={1}>
                    {
                        original?.contentUrl &&
                        <a href={config.apiHost + original.contentUrl}
                           target={"_blank"}
                           rel="noreferrer">{config.apiHost + original.contentUrl}</a>
                    }
                </Box>
            );
        }

        return null;
    }

    const renderImageUploads = (item, index) => {
        if (!Object.keys(itemImages).length) {
            return null;
        }

        return (
            <>
                <Box display={!translationLanguage ? 'block' : 'none'}>
                    <ImageUpload
                        ref={item.imageRef}
                        setImageIRI={(value) => updateItem(index, 'image', value)}
                        showError={showError}
                        catchErrors={catchErrors}
                        setUploadStatus={(value) => updateItem(index, 'imageUpload', value)}
                        originalIRI={item.imageOriginal?.['@id']}
                        returnType={'object'}
                    />
                    {getImageUploadOriginalLink(item.imageOriginal)}
                </Box>
                {
                    Object.keys(itemImages).map((key) => (
                        <Box display={translationLanguage === key ? 'block' : 'none'} key={key + '_' + index}>
                            <ImageUpload
                                ref={itemImages[key][index].ref}
                                setImageIRI={(value) => {
                                    updateItemImages(key, index, 'image', value);
                                    updateItemImages(key, index, 'original', value);
                                }}
                                showError={showError}
                                catchErrors={catchErrors}
                                setUploadStatus={(value) => updateItemImages(key, index, 'upload', value)}
                                originalIRI={itemImages[key][index]?.original['@id']}
                                returnType={'object'}
                            />
                            {getImageUploadOriginalLink(itemImages[key][index]?.original)}
                        </Box>
                    ))
                }
            </>
        );
    };

    const modalBody = (
        <React.Fragment>
            <ModuleElementDimension
                dimension={dimension}
                setDimension={setDimension}
                showDimension={!props.checkboxDimension}
                dimensions={props.dimensions}
            />
            <Box mb={2}>
                <FormControlLabel
                    control={<Checkbox checked={checkboxGlobalTime}
                                       onChange={() => setCheckboxGlobalTime(prev => !prev)}
                                       name={GlobalTransIntl('time', intl)}/>}
                    label={GlobalTransIntl('time', intl)}
                />
                {
                    checkboxGlobalTime &&
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <ModuleTimePicker setValue={setGlobalTime} value={globalTime}/>
                        </Grid>
                    </Grid>
                }
            </Box>
            <Box mb={2}>
                <Divider mb={2}/>
            </Box>
            <Box mb={2}>
                <Grid container spacing={2}>
                    {items.map((item, index) => (
                        <Grid item xs={6} key={item.id}>
                            <Box borderBottom={2} mb={2}>
                                <Box mb={2}>
                                    <TextField label={GlobalTransIntl('name', intl)}
                                               autoComplete={'off'}
                                               variant="outlined"
                                               onChange={(e) => {
                                                   updateItem(index, "name", e.target.value)
                                               }}
                                               value={item.name}
                                               required
                                    />
                                </Box>
                                <Box mb={2} display={checkboxGlobalTime ? 'none' : 'block'}>
                                    <ModuleTimePicker setValue={(value) => {
                                        updateItem(index, 'time', value)
                                    }} value={item.time} />
                                </Box>
                                <Box mb={2}>
                                    <Notifications
                                        success={notificationSuccess}
                                        error={notificationError}
                                        errorMessage={errorMessage}
                                    />
                                    {renderImageUploads(item, index)}
                                </Box>
                                <Box mb={2}>
                                    <TranslationsInput
                                        field={'question'}
                                        fieldType={'array'}
                                        fieldIndex={index}
                                        defaultValue={item.question}
                                        setDefaultValue={(value) => {
                                            updateItem(index, "question", value)
                                        }}
                                        translations={translations[index]}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('question', intl)}
                                        setTranslations={(value) => setItemTranslations(index, value)}
                                        attributes={{
                                            required: true,
                                            autoComplete: 'off',
                                        }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Typography>{GlobalTransIntl('options', intl)}</Typography>
                                </Box>
                                <ModuleElementOptions
                                    label={'option'}
                                    options={item.options}
                                    setOptions={(options) => {
                                        updateItem(index, 'options', options)
                                    }}
                                    answer={answers[index]?.answer !== undefined ? answers[index].answer : ''}
                                    setAnswer={(value) => {
                                        updateAnswer(index, "answer", value)
                                    }}
                                    translations={translations[index]}
                                    setTranslations={(value) => setItemTranslations(index, value)}
                                    translationLanguage={translationLanguage}
                                    translationsField={'options'}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box mb={2} textAlign={'center'}>
                                            {GlobalTransIntl('wrong', intl)}
                                        </Box>
                                        {
                                            itemFinish(
                                                index,
                                                'wrong',
                                                answers[index].wrong.finished
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box mb={2} textAlign={'center'}>
                                            {GlobalTransIntl('right', intl)}
                                        </Box>
                                        {
                                            itemFinish(
                                                index,
                                                'right',
                                                answers[index].right.finished
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Button variant="contained" onClick={addItem}>
                {GlobalTransIntl('add_item', intl)}
            </Button>
        </React.Fragment>
    );

    const getItemTime = (item) => {
        if (!item.time) {
            return null;
        }

        const time = new Date(item.time * 1000).toISOString().substring(14, 19);

        return (
            <span> ({time})</span>
        );
    }

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>

            <Box>
                {
                    props.items.map(item => (
                        <div key={item.id}>{item.name}{getItemTime(item)}</div>
                    ))
                }
            </Box>
        </Box>
    );
};

AdaptiveElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    dimensions: PropTypes.array.isRequired,
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
}

export const AdaptiveElementConfig = {
    title: 'adaptive_element',
    items: [],
    globalTime: 0,
    validationType: 'adaptive',
}

export default AdaptiveElement;
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl.macro";
import {useStore} from "../../store/useStore";
import axios from "axios";
import Notifications from "../notifications/Notifications";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Grid} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import {useIntl} from "react-intl";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";
import {helperCatchErrors, scrollToTop} from "../../helper/Helper";
import TranslationsInput from "../translations/TranslationsInput";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TextField from "@material-ui/core/TextField";
import ImageUpload from "../imageUpload/ImageUpload";

function ConstructsCreate () {
    const { state, dispatch } = useStore();
    const intl = useIntl();
    const imageRef = useRef();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [name, setName] = useState(state.editForm.name || '');
    const [description, setDescription] = useState(state.editForm.description || '');
    const [cakeColor, setCakeColor] = useState(state.editForm.cakeColor || '');
    const [imageOriginal] = useState(state.editForm.image || '');
    const [image, setImage] = useState('');
    const [imageUpload, setImageUpload] = useState(false);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (state.editForm) {
            dispatch({type: "resetEditForm"});
        }
    }, [state.editForm, dispatch]);

    const sendSubmit = useCallback(() => {
        resetNotifications();

        const data = {
            name,
            description,
            cakeColor,
            translations
        };

        if (image) {
            data.image = image;
        }

        if (editForm) {
            axios.put(config.apiUrl + `/constructs/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/ld+json'}}))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/constructs`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        setName('');
                        setDescription('');
                        setCakeColor('');
                        setImage('');
                        setNotificationSuccess(true);
                    } else {
                        setNotificationError(true);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data['violations']) {
                        setNotificationErrorMessage(error.response.data['violations'][0].message);
                        setNotificationError(true);
                    } else if (error.response) {
                        setNotificationErrorMessage('');
                        setNotificationError(true);
                    }
                });
        }
    }, [cancelToken.token, catchErrors, description, cakeColor, image, translations, editForm, name, showError, state.token]);

    const onSubmit = (e) => {
        e.preventDefault();
        imageRef.current.sendFile();
    }

    useEffect(() => {
        if (imageUpload) {
            setImageUpload(false);
            sendSubmit();
        }
    }, [imageUpload, sendSubmit]);

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='constructions.edit.headline' defaultMessage='Edit construct'/>
        }

        return <FormattedMessage id='constructions.create.headline' defaultMessage='Create construct'/>
    }

    return (
        <Row>
            <Col cs={12} lg={6}>
                <Paper className={`constructs--create-paper`}>
                    <CardContent>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <h1>
                                        <Headline/>
                                    </h1>
                                </Grid>
                                <Grid item>
                                    <TranslationLanguages selectedLanguage={translationLanguage} setLanguage={setTranslationLanguage}/>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={2} pr={2} pb={2}>
                            <Notifications success={notificationSuccess}
                                           error={notificationError}
                                           errorMessage={GlobalTrans(notificationErrorMessage)}
                            />
                        </Box>
                        <Form className={`constructs--create-form`} onSubmit={onSubmit}>
                            <Box mb={2}>
                                <TranslationsInput
                                    field={'name'}
                                    defaultValue={name}
                                    setDefaultValue={setName}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('name', intl)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        required: true,
                                        id: `constructs-create--name`
                                    }}
                                />
                            </Box>
                            <Box mb={2}>
                                <TranslationsInput
                                    field={'description'}
                                    defaultValue={description}
                                    setDefaultValue={setDescription}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('description', intl)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        required: true,
                                        multiline: true,
                                        minRows: 4,
                                        id: `constructs-create--description`
                                    }}
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField label={GlobalTransIntl('cake_color', intl)}
                                           id={`constructs-create--cake-color`}
                                           autoComplete={'off'}
                                           variant="outlined"
                                           onChange={(e) => {
                                               setCakeColor(e.target.value)
                                           }}
                                           value={cakeColor}
                                />
                            </Box>
                            <Box mb={2}>
                                <ImageUpload
                                    ref={imageRef}
                                    setImageIRI={setImage}
                                    showError={showError}
                                    catchErrors={catchErrors}
                                    setUploadStatus={setImageUpload}
                                    originalIRI={imageOriginal?.['@id']}
                                />
                                {
                                    !!imageOriginal?.contentUrl &&
                                    <Grid item xs={12}>
                                        <img  className={'img-fluid'} src={config.apiHost + imageOriginal.contentUrl} alt={''}/>
                                    </Grid>
                                }
                            </Box>
                            <Button variant="primary" type="submit" className={`constructs--create-submit`}>
                                {
                                    (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                                }
                            </Button>
                        </Form>
                    </CardContent>
                </Paper>
            </Col>
        </Row>
    );
}

export default ConstructsCreate;
import React from 'react';
import {useStore} from "../../store/useStore";
import GlobalTrans from "../../helper/GlobalTrans";
import pages from "../../config/pages";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {Paper} from "@material-ui/core";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

const OverallTemplateOverview = () => {
    const { dispatch } = useStore();

    const columns = [
        {
            field: 'procedureObject', headerName: GlobalTrans('field_assessment'), width: 300,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value} (v. {params.row.procedureObject?.version})</span>;
                } else {
                    return '-';
                }
            }
        },
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.OVERALL_TEMPLATE_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={()=> onEdit(props)} aria-label={GlobalTrans('title_edit')} title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    const filter = ApiFilterBuilder([
        {
            name: 'isNewestVersion',
            value: 1,
        }
    ]);

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'overall_templates'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'select',
                        getUrl: 'procedures' + filter,
                        name: 'procedureObject',
                        label: GlobalTrans('assessment'),
                        filterOperator: 'equals'
                    }
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
};

export default OverallTemplateOverview;
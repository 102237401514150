import React, {useCallback, useEffect} from "react";
import {useStore} from "../../store/useStore";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";

// Config
import pages from "../../config/pages";

// Helper
import {getUrlParam, scrollToTop} from "../../helper/Helper";
import Token from "../../helper/Token";

// Material-UI
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Container from "@material-ui/core/Container";

// Components
import Login from "../login/Login";
import Dashboard from "../dashboard/Dashboard";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import PinsCreate from "../pins/PinsCreate";
import PinsOpen from "../pins/PinsOpen";
import PinsInvited from "../pins/PinsInvited";
import PinsStarted from "../pins/PinsStarted";
import PinsResults from "../pins/PinsResults";
import ResultDetails from "../resultDetails/ResultDetails";
import ProjectOverview from "../project/ProjectOverview";
import ProjectForm from "../project/ProjectForm";
import CustomerOverview from "../customer/CustomerOverview";
import CustomerCreate from "../customer/CustomerCreate";
import UserOverview from "../user/UserOverview";
import UserCreate from "../user/UserCreate";
import ThemeOverview from "../theme/ThemeOverview";
import ThemeForm from "../theme/ThemeForm";
import Translations from "../translation/Translations";
import Translation from "../translation/Translation";
import CountriesOverview from "../countries/CountriesOverview";
import CountriesCreate from "../countries/CountriesCreate";
import LanguagesOverview from "../languages/LanguagesOverview";
import LanguagesCreate from "../languages/LanguagesCreate";
import NormgroupsOverview from "../normgroups/NormgroupsOverview";
import NormgroupsCreate from "../normgroups/NormgroupsCreate";
import Modules from "../modules/Modules";
import ModulesOverview from "../modules/ModulesOverview";
import ChangePassword from "../changePassword/ChangePassword";
import AssessmentsOverview from "../assessments/AssessmentsOverview";
import AssessmentsForm from "../assessments/AssessmentsForm";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import DimensionsOverview from "../dimensions/DimensionsOverview";
import DimensionsCreate from "../dimensions/DimensionsCreate";
import TokenRefresher from "../../helper/TokenRefresher";
import ConstructsOverview from "../constructs/ConstructsOverview";
import ConstructsCreate from "../constructs/ConstructsCreate";
import AssessmentNormgroupsForm from "../assessments/AssessmentNormgroupsForm";
import ScalesOverview from "../scales/ScalesOverview";
import ScalesForm from "../scales/ScalesForm";
import ForcedChoiceWeightingOverview from "../forcedChoiceWeighting/ForcedChoiceWeightingOverview";
import ForcedChoiceWeightingForm from "../forcedChoiceWeighting/ForcedChoiceWeightingForm";
import GetLanguages from "../getLanguages/GetLanguages";
import RefreshToken from "../refreshToken/RefreshToken";
import ReportDimensionAveragesOverview from "../reportDimensionAverages/ReportDimensionAveragesOverview";
import ReportDimensionAveragesForm from "../reportDimensionAverages/ReportDimensionAveragesForm";
import ThreeSixtyKeysOverview from "../threeSixtyKeys/ThreeSixtyKeysOverview";
import ThreeSixtyKeysCreate from "../threeSixtyKeys/ThreeSixtyKeysCreate";
import InvitationsOverview from "../invitations/InvitationsOverview";
import InvitationsCreate from "../invitations/InvitationsCreate";
import DataProtectionsOverview from "../dataProtections/DataProtectionsOverview";
import DataProtectionsCreate from "../dataProtections/DataProtectionsCreate";
import PinExport from "../export/PinExport";
import NormgroupExport from "../export/NormgroupExport";
import DimensionSortingOverview from "../dimensionSorting/DimensionSortingOverview";
import DimensionSortingForm from "../dimensionSorting/DimensionSortingForm";
import GlobalPinSearch from "../globalPinSearch/GlobalPinSearch";
import AssessmentDimensionTemplateForm from "../assessments/AssessmentDimensionTemplateForm";
import OverallTemplateOverview from "../overallTemplate/OverallTemplateOverview";
import OverallTemplateForm from "../overallTemplate/OverallTemplateForm";

function Content() {
    const intl = useIntl();
    const {state, dispatch} = useStore();

    const queryEmail = getUrlParam('email');
    const queryToken = getUrlParam('token');

    useEffect(() => {
        document.title = GlobalTransIntl('document_title', intl);
    }, [state.locale, intl]);

    const isSetPassword = useCallback(() => {
        return queryEmail && queryToken;
    }, [queryEmail, queryToken]);

    const ValidateJWT = useCallback((props) => {
        const deleteToken = () => {
            dispatch({type: "deleteTokens"});
            dispatch({type: "setTokenExpired", payload: true});
        }

        if (!state.token && state.refreshToken && !isSetPassword()) {
            Token.refresh(
                (res) => {
                    dispatch({
                        type: 'setTokens',
                        payload: {
                            token: res.data.token,
                            refreshToken: res.data.refresh_token
                        }
                    })
                },
                () => {
                    deleteToken();
                },
                state.refreshToken
            )

            return <RefreshToken/>;
        }

        return props.children;
    }, [dispatch, state.refreshToken, state.token, isSetPassword]);

    const getContentComponent = (loggedIn = false) => {
        // check if query parameter for setPassword are set
        if (queryEmail && queryToken) {
            return <ForgotPassword email={queryEmail} token={queryToken}/>;
        }

        if (loggedIn) {
            scrollToTop();

            switch (state.page) {
                case pages.DASHBOARD:
                    return <Dashboard/>
                case pages.PINS_CREATE:
                    return <PinsCreate/>
                case pages.PINS_OPEN:
                    return <PinsOpen/>
                case pages.PINS_INVITED:
                    return <PinsInvited/>
                case pages.PINS_STARTED:
                    return <PinsStarted/>
                case pages.PINS_RESULTS:
                    return <PinsResults/>
                case pages.PINS_RESULTS_DETAILS:
                    return <ResultDetails/>
                case pages.PROJECT_OVERVIEW:
                    return <ProjectOverview/>
                case pages.PROJECT_CREATE:
                    return <ProjectForm/>
                case pages.CUSTOMER_OVERVIEW:
                    return <CustomerOverview/>
                case pages.CUSTOMER_CREATE:
                    return <CustomerCreate/>
                case pages.USER_OVERVIEW:
                    return <UserOverview/>
                case pages.USER_CREATE:
                    return <UserCreate/>
                case pages.THEME_OVERVIEW:
                    return <ThemeOverview/>
                case pages.THEME_CREATE:
                    return <ThemeForm/>
                case pages.TRANSLATIONS:
                    return <Translations/>
                case pages.TRANSLATION:
                    return <Translation/>
                case pages.COUNTRIES_OVERVIEW:
                    return <CountriesOverview/>
                case pages.COUNTRIES_CREATE:
                    return <CountriesCreate/>
                case pages.LANGUAGES_OVERVIEW:
                    return <LanguagesOverview/>
                case pages.LANGUAGES_CREATE:
                    return <LanguagesCreate/>
                case pages.NORMGROUPS_OVERVIEW:
                    return <NormgroupsOverview/>
                case pages.NORMGROUPS_CREATE:
                    return <NormgroupsCreate/>
                case pages.CONSTRUCTS_OVERVIEW:
                    return <ConstructsOverview/>
                case pages.CONSTRUCTS_CREATE:
                    return <ConstructsCreate/>
                case pages.DIMENSIONS_OVERVIEW:
                    return <DimensionsOverview/>
                case pages.DIMENSIONS_CREATE:
                    return <DimensionsCreate/>
                case pages.MODULES_CREATE:
                    return <Modules/>
                case pages.MODULES_OVERVIEW:
                    return <ModulesOverview/>
                case pages.ACCOUNT_CHANGE_PASSWORD:
                    return <ChangePassword/>
                case pages.ASSESSMENT_OVERVIEW:
                    return <AssessmentsOverview/>
                case pages.ASSESSMENT_CREATE:
                    return <AssessmentsForm/>
                case pages.ASSESSMENT_NORMGROUPS:
                    return <AssessmentNormgroupsForm/>
                case pages.ASSESSMENT_DIMENSION_TEMPLATES:
                    return <AssessmentDimensionTemplateForm/>
                case pages.SCALES_OVERVIEW:
                    return <ScalesOverview/>
                case pages.SCALES_CREATE:
                    return <ScalesForm/>
                case pages.FORCED_CHOICE_WEIGHTING_OVERVIEW:
                    return <ForcedChoiceWeightingOverview/>
                case pages.FORCED_CHOICE_WEIGHTING_CREATE:
                    return <ForcedChoiceWeightingForm/>
                case pages.REPORT_DIMENSION_AVERAGES_OVERVIEW:
                    return <ReportDimensionAveragesOverview/>
                case pages.REPORT_DIMENSION_AVERAGES_CREATE:
                    return <ReportDimensionAveragesForm/>
                case pages.THREE_SIXTY_KEYS_OVERVIEW:
                    return <ThreeSixtyKeysOverview/>
                case pages.THREE_SIXTY_KEYS_CREATE:
                    return <ThreeSixtyKeysCreate/>
                case pages.INVITATIONS_OVERVIEW:
                    return <InvitationsOverview/>
                case pages.INVITATIONS_CREATE:
                    return <InvitationsCreate/>
                case pages.DATA_PROTECTION_OVERVIEW:
                    return <DataProtectionsOverview/>
                case pages.DATA_PROTECTION_CREATE:
                    return <DataProtectionsCreate/>
                case pages.EXPORT_PIN:
                    return <PinExport/>
                case pages.EXPORT_NORMGROUP:
                    return <NormgroupExport/>
                case pages.DIMENSION_SORTING_OVERVIEW:
                    return <DimensionSortingOverview/>
                case pages.DIMENSION_SORTING_CREATE:
                    return <DimensionSortingForm/>
                case pages.GLOBAL_PIN_SEARCH:
                    return <GlobalPinSearch/>
                case pages.OVERALL_TEMPLATE_OVERVIEW:
                    return <OverallTemplateOverview/>
                case pages.OVERALL_TEMPLATE_CREATE:
                    return <OverallTemplateForm/>
                default:
                    return <Dashboard/>;
            }
        } else {
            switch (state.page) {
                case pages.FORGOT_PASSWORD:
                    return <ForgotPassword/>;
                default:
                    return (
                        <React.Fragment>
                            <GetLanguages/>
                            <Login/>
                        </React.Fragment>
                    );
            }
        }
    }

    return (
        (
            (!state.token || isSetPassword()) &&
            <ValidateJWT>
                <Header login={true}/>
                {
                    getContentComponent()
                }
            </ValidateJWT>
        ) || (
            state.token &&
            <React.Fragment>
                <TokenRefresher/>
                <GetLanguages/>
                <Header/>
                <div className='content-wrapper'>
                    <CssBaseline/>
                    <Drawer
                        variant="permanent"
                        className='sidebar-wrapper'
                        classes={{
                            paper: 'sidebar-paper',
                        }}
                    >
                        <Sidebar/>
                    </Drawer>
                    <main className='main-content'>
                        <Container className='main-content--container' maxWidth={"xl"} id={'main-content_container'}>
                            {
                                getContentComponent(true)
                            }
                        </Container>
                    </main>
                </div>
            </React.Fragment>
        )
    );
}

export default Content;